import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const ContentHeader = ({ title, description, ...props }) => {
  return (
    <Box mx='20px' {...props}>
      <Text fontSize='32px' color='black' fontWeight='600'>
        {title}
      </Text>
      <Text fontSize='16px' color='camera.text'>
        {description}
      </Text>
    </Box>
  );
};

export default ContentHeader;
