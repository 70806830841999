import {
  Box,
  Table,
  Thead,
  Tbody,
  Image,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Icon,
  Spinner,
  Flex,
  Button,
} from '@chakra-ui/react';
import { FaClock } from "react-icons/fa6";

import React, { useEffect, useState } from 'react';
import './index.css';
import ContentHeader from '../ContentHeader';
import { IoInformationCircle } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router';

import { DataService } from '../../services';
import moment from 'moment';

const CameraDataTable = () => {
  const navigate = useNavigate();
  const [runsData, setRunsData] = useState([]);
  const [fetchRunsData, setFetchRunsData] = useState(false);
  const params = useParams();

  useEffect(() => {
    const fetchRunsData = async () => {
      setFetchRunsData(true);
      const allRunsData = await DataService.getAllRuns(params.id);
      setRunsData(allRunsData);
      setFetchRunsData(false);
    };

    fetchRunsData();
  }, [params]);

  if (fetchRunsData) {
    return (
      <Flex
        height="calc(100vh - 70px)"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Box>
      <ContentHeader title="Inspections" description="View and manage all inspections" />

      <TableContainer
        boxShadow="rgba(0,0,0,0.05) 0px 5px 14px"
        borderRadius="20px"
        m="16px 20px 0px 20px"
      >
        <Table variant="simple" className="table">
          <Thead>
            <Tr>
              
              <Th
                color="#a0aec0"
                textTransform="capitalize"
                fontSize="16px"
                fontWeight="400"
              >
                Started At
              </Th>
              <Th
                color="#a0aec0"
                textTransform="capitalize"
                fontSize="16px"
                fontWeight="400"
              >
                Duration
              </Th>
              <Th
                color="#a0aec0"
                textTransform="capitalize"
                fontSize="16px"
                fontWeight="400"
              >
                Status
              </Th>
              <Th
                color="#a0aec0"
                textTransform="capitalize"
                fontSize="16px"
                fontWeight="400"
              >
                Anomaly Score
              </Th>

              <Th
                color="#a0aec0"
                textTransform="capitalize"
                fontSize="16px"
                fontWeight="400"
                textAlign="center"
              >
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {runsData.map((data, index) => (
              
              <Tr
                key={index}
                style={{
                  borderBottom: runsData.length === index + 1 ? 'none' : '',
                }}
              >
                
                <Td minW="142px">{moment(data.run_start).format('YYYY-MM-DD HH:mm:ss')}</Td>
                <Td>{data.run_duration}</Td>
                <Td>
                  <Text
                    bg="camera.blue.300"
                    color="white"
                    fontWeight="700"
                    padding="8px 20px"
                    borderRadius="16px"
                    width="fit-content"
                  >
                    {data.run_status}
                  </Text>
                </Td>
                <Td><Text
                    bg="green"
                    color="white"
                    fontWeight="700"
                    padding="8px 20px"
                    borderRadius="16px"
                    width="fit-content"
                  >
                    normal
                  </Text></Td>

                <Td
                  textAlign="center"
                  cursor="pointer"
                >
                  <Button onClick={() => navigate(`/runs/${data.run_id}/overview`)} borderRadius='16px'
                    p='0px 12px'
                    mr='2'
                    _hover={{ backgroundColor: 'white' }}
                    color='black'
                    border='1px black solid'
                    fontWeight='700'
                    size='sm'>View</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      
    </Box>
  );
};

export default CameraDataTable;
