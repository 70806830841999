import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import sAxios from '../../services/sAxios';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Spinner, Progress, Button } from '@chakra-ui/react';
import './index.css';

const FolderUploader = ({ dropAreaStyle, disabled = false, tray }) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFolderValid, setIsFolderValid] = useState(false);
  const [zippingProgress, setZippingProgress] = useState({});

  const handleFolderSelect = (event) => {
    const selectedFiles = Array.from(event.target.files).filter((file) => {
      const allowedExtensions = [".dat", ".mp4", ".json"];
      return allowedExtensions.some((ext) => file.name.endsWith(ext));
    });

    if (selectedFiles.length > 0) {
      const folderPath = selectedFiles[0].webkitRelativePath.split('/')[0];
      const folderNameRegex = /^\d{8}_\d{2}_\d{2}_\d{2}_[a-f0-9]{8}$/;
      if (folderNameRegex.test(folderPath)) {
        setIsFolderValid(true);
        setFiles(selectedFiles);
        setProgress({}); // Reset progress for new files
        setZippingProgress({});
        setIsModalOpen(true); // Open modal when files are selected
      } else {
        alert('Invalid folder name. Please select a folder with the correct format: YYYYMMDD_HH_MM_SS_XXXXXXXX');
        setIsFolderValid(false);
      }
    } else {
      alert('No valid files selected. Please select .dat, .mp4, or .json files.');
    }
  };
  
  const zipAndUploadDatFiles = (datFiles) => {
    const uploadPromises = datFiles.map(async (file) => {
      setZippingProgress((prev) => ({
        ...prev,
        [file.name]: 'Zipping...'
      }));
  
      const zip = new JSZip();
      // Preserve the relative path (e.g., `multisensor/...`)
      zip.file(file.webkitRelativePath, file);
  
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const zipFile = new File(
        [zipBlob],
        `${file.name}.zip`, // Use the file name for the ZIP
        { type: "application/zip" }
      );
  
      setZippingProgress((prev) => ({
        ...prev,
        [file.name]: 'Uploading...'
      }));
  
      await uploadFile(zipFile, file.webkitRelativePath); // Pass the relative path for backend reconstruction
  
      setZippingProgress((prev) => ({
        ...prev,
        [file.name]: 'Done'
      }));
    });
  
    return Promise.all(uploadPromises);
  };
  

  const uploadFile = async (file, originalFileName) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('tray_id', tray.tray_id); // Include tray ID in the upload

      sAxios.post('/runs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress((prevProgress) => ({
            ...prevProgress,
            [originalFileName]: percentCompleted,
          }));
        },
      })
      .then(() => resolve())
      .catch((error) => reject(error));
    });
  };

  const uploadFiles = async () => {
    setLoading(true);
    try {
      const datFiles = files.filter((file) => file.name.endsWith(".dat"));
      const nonDatFiles = files.filter((file) => !file.name.endsWith(".dat"));

      // Zip and upload each .dat file individually in parallel
      await zipAndUploadDatFiles(datFiles);

      // Upload non-dat files in parallel
      const nonDatUploadPromises = nonDatFiles.map((file) => uploadFile(file, file.name));
      await Promise.all(nonDatUploadPromises);

      alert('All files uploaded successfully!');
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Some files failed to upload.');
    } finally {
      setLoading(false);
      setIsModalOpen(false); // Close modal on upload completion
    }
  };

  useEffect(() => {
    if (isModalOpen && files.length > 0 && isFolderValid) {
      uploadFiles();
    }
  }, [isModalOpen, files, isFolderValid]);

  return (
    <div>
      <div className="drop-area" style={dropAreaStyle}>
        <Button
          borderRadius='16px'
          p='0px 24px'
          mr='2'
          bg='camera.blue.light'
          _hover={{ backgroundColor: '#a1aebf' }}
          color='white'
          fontWeight='700'
          size='sm'
          disabled={disabled || loading}
          as="label"
        >
          Upload from SD
          <input
            type="file"
            webkitdirectory="true"
            directory="true"
            multiple
            style={{ display: 'none' }}
            onChange={handleFolderSelect}
          />
        </Button>
      </div>

      <Modal isOpen={isModalOpen} onClose={loading ? undefined : () => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uploading Files</ModalHeader>
          <ModalBody>
            {files.map((file) => (
              <div key={file.name} style={{ marginBottom: '10px' }}>
                <span>{file.name} - {zippingProgress[file.name] || (progress[file.name] ? 'Uploading...' : 'Waiting')}</span>
                <Progress
                  value={progress[file.name] || 0}
                  size='sm'
                  colorScheme='blue'
                  mt='2'
                />
              </div>
            ))}
            {loading && <Spinner size='md' mt='4' />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FolderUploader;
