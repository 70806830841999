import React, { createContext, useState } from 'react';
import { cloneDeep } from 'lodash';

export const AppContext = createContext({
  videos: [],
  selectedVideoIndex: 0,
  isAuthenticated: false,  // Default value
});

const AppContextProvider = (props) => {
  const [videos, setVideos] = useState([]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [isAuthenticated, setAuthenticated] = useState(false);  // Authentication state

  const initializeVideos = (videos) => {
    setVideos(cloneDeep(videos));
  };

  const updateSelectedVideoIndex = (index) => {
    setSelectedVideoIndex(index || 0);
  };

  const updateVideo = (updatedVideo) => {
    setVideos((prev) =>
      prev.map((data) => {
        return data.video_chunk_id === updatedVideo.video_chunk_id
          ? { ...data, ...updatedVideo }
          : { ...data };
      })
    );
  };

  return (
    <AppContext.Provider
      value={{
        videos,
        selectedVideoIndex,
        isAuthenticated,
        setAuthenticated,
        initializeVideos,
        updateVideo,
        updateSelectedVideoIndex,
      }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
