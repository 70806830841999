import { Box } from '@chakra-ui/react';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment-timezone';

const RunsChart = ({ labelData, datas, annotations, onDataPointIndexChange, yLimit }) => {
  const timezone = 'Europe/Berlin';

  // Determine if `datas` is an array of objects (multiple datasets) or a single dataset
  const isMultiDataset = Array.isArray(datas) && datas[0]?.data;

  // Prepare series data based on the input format
  const series = isMultiDataset
    ? datas.map((dataSet) => ({
        name: dataSet.label,
        data: dataSet.data.map((item) => [item.timestamp, item.value]),
      }))
    : [
        {
          name: labelData,
          data: datas.map((item) => [item.timestamp, item.value]),
        },
      ];

  // Prepare annotations
  const xAnnotations = annotations.map((item) => ({
    x: moment.tz(item.timestamp, timezone).valueOf(),
    label: {
      show: true,
      text: item.aruco_id?.toString() || '', // Ensure aruco_id is a string
    },
  }));

  const options = {
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    chart: {
      height: 200,
      type: 'line',
      id: labelData,
      group: 'Trays',
      events: {
        mouseMove: function (event, chartContext, config) {
          if (config.dataPointIndex >= 0) {
            if (onDataPointIndexChange) {
              onDataPointIndexChange(config.dataPointIndex);
            }
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          label: {
            show: true,
          },
        },
      ],
      xaxis: xAnnotations,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (value) {
          return moment(value).tz(timezone).format('HH:mm:ss');
        },
      },
    },
    yaxis: {
      max: yLimit,
      title: {
        text: labelData,
      },
    },
    tooltip: {
      shared: true,
      x: {
        format: 'HH:mm:ss',
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          return `${value}`;
        },
      },
    },
  };

  return (
    <Box>
      <ReactApexChart
        key={labelData}
        options={options}
        series={series}
        type="area"
        height="250"
      />
    </Box>
  );
};

export default RunsChart;
