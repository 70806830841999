import React, { useEffect, useState, useContext } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { FiHome, FiMenu } from 'react-icons/fi';

import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

import circle from '../../assets/three-quarter-circle.svg';
import { AppContext } from '../../context/app.context';  // Adjust the import path as necessary



export default function Sidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated } = useContext(AppContext); // Using AppContext to check if user is authenticated

  const linkItems = isAuthenticated ? [
    { name: 'My Molds', icon: FiHome, href: '/' }
  ] : [
    { name: 'Login', icon: FiHome, href: '/login' }
  ];
  
  return (
    <Box minH='100vh' bg='white'>
      <SidebarContent
        linkItems={linkItems} 
        onClose={() => onClose}
        display={{ base: 'none', lg: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='xs'>
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', lg: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, lg: 60 }} p='4'>
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({linkItems, onClose, ...rest }) => {
  return (
    <Box
      bg='#f5f5f5'
      borderRight='1px'
      borderRightColor='#f7f7f7'
      w={{ base: 'full', lg: '60' }}
      pos='fixed'
      h='full'
      {...rest}>
      <Flex h='20' alignItems='center' mx='8' justifyContent='space-between'>
        <Flex alignItems='center' gap='12px' color='black'>
          <Image
            src={circle}
            alt='circle'
            width='24px'
          />
          <Text fontSize='20px' fontWeight='700'>
             Smart Mold
          </Text>
        </Flex>
        <CloseButton display={{ base: 'flex', lg: 'none' }} onClick={onClose} />
      </Flex>
      {linkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} link={link.href}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, link, children, ...rest }) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(window.location.pathname)

  useEffect(() => {
    setCurrentLocation(location.pathname)
  }, [location]);

  return (
    <ChakraLink as={ReactRouterLink} to={link}  _hover={{
      textDecoration: 'unset'
    }}>
      <Flex
        align='center'
        p='4'
        mx='4'
        borderRadius='lg'
        role='group'
        cursor='pointer'
        bg={currentLocation === link ? 'cyan.400': 'transparent'}
        color={currentLocation === link ? 'white': 'inherit'}
        textDecoration='unset'
        _hover={{
          bg: 'cyan.400',
          color: 'white',
          textDecoration: 'unset'
        }}
        {...rest}>
        {icon && (
          <Icon
            mr='4'
            fontSize='16'
            _groupHover={{
              color: 'white'
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </ChakraLink>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, lg: 24 }}
      height='20'
      alignItems='center'
      bg='#f5f5f5'
      borderBottomWidth='1px'
      borderBottomColor='#F7F7F7'
      justifyContent='flex-start'
      {...rest}>
      <IconButton
        variant='outline'
        onClick={onOpen}
        aria-label='open menu'
        icon={<FiMenu />}
      />

      <Flex alignItems='center' gap='12px' color='black' ml='12px'>
        <Image
          src={circle}
          alt='circle'
          width='24px'
          transform='rotate(-45deg)'
        />
        <Text fontSize='20px' fontWeight='700'>
          Smart Mold
        </Text>
      </Flex>
    </Flex>
  );
};
