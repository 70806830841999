import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/app.context';
import {
    FormControl,
    FormLabel,
    Input,
    Box,
    Card,
    CardBody,
    CardFooter,
    Flex,
    Icon,
    Image,
    Spinner,
    Stack,
    Text,
    useToast, Tooltip,
    Button
  } from '@chakra-ui/react';
import { DataService } from '../../services';


function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setAuthenticated } = useContext(AppContext);
  const navigate = useNavigate();
  const toast = useToast();
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await DataService.login(username, password);
      setAuthenticated(true);
      navigate('/');
    } catch (error) {
      toast({
        title: 'Authentication Failed',
        description: 'Invalid username or password',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.error('Login error:', error);
      setAuthenticated(false);
    }
  };

  return (
    <Flex mt="20px" gap="40px">
    <Box p={4}>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel htmlFor='username'>Username</FormLabel>
          <Input id='username' type='text' value={username} onChange={(e) => setUsername(e.target.value)} />
        </FormControl>
        <FormControl isRequired mt={4}>
          <FormLabel htmlFor='password'>Password</FormLabel>
          <Input id='password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
        </FormControl>
        <Button mt={4} colorScheme='blue' type='submit'>Login</Button>
      </form>
    </Box>
    </Flex>
  );
}

export default LoginForm;
