import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './index.css';
import {
  LoginForm,
} from '../';
import Sidebar from '../Sidebar/Sidebar';

const Navbar = () => {
  return (
    <Flex flexDir='column'>
      <Sidebar>
        <Box>
          <Box w={{base: '100%', lg: '90%'}} margin='auto' my='20px'>
            <Outlet />
          </Box>
        </Box>
      </Sidebar>
    </Flex>
  );
};

export default Navbar;
