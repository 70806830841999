import './App.css';
import { Box } from '@chakra-ui/react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import {
  CameraDataTable,
  DeviceOverview,
  Navbar,
  RunOverView,
  RunVideos,
  LoginForm,
} from './components';
import ProtectedRoute from './components/ProtectedRoute';  // Make sure to import ProtectedRoute
import AppContextProvider from './context/app.context';

function App() {
  return (
    <Box className='App'>
      <Router>
        <AppContextProvider>
          <Routes>
            <Route element={<Navbar />}>
              <Route path='/login' element={<LoginForm />} />
              <Route path='/' element={<ProtectedRoute><DeviceOverview /></ProtectedRoute>} />
              <Route path='/trays/:id' element={<ProtectedRoute><CameraDataTable /></ProtectedRoute>} />
              <Route path='/runs/:id/overview' element={<ProtectedRoute><RunOverView /></ProtectedRoute>} />
              <Route path='/runs/:id/videos' element={<ProtectedRoute><RunVideos /></ProtectedRoute>} />
            </Route>
          </Routes>
        </AppContextProvider>
      </Router>
    </Box>
  );
}

export default App;
