import { Box, Flex, Image, Spinner, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState, useRef } from 'react';
import RunsChart from '../RunsChart';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router';
import { DataService } from '../../services';
import { AppContext } from '../../context/app.context';

import { FaClock } from "react-icons/fa6";
import ContentHeader from '../ContentHeader';

const RunOverView = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [runData, setRunData] = useState({})

  const { videos, initializeVideos, updateSelectedVideoIndex } = useContext(AppContext);

  const [fetchingRunData, setFetchingRunData] = useState(false);
  const videosFlexRef = useRef(null);

  useEffect(() => {
    const fetchRunsData = async () => {
      setFetchingRunData(true);
      const runData = await DataService.getRunById(id);
      setRunData(runData)

      initializeVideos(runData?.run_video_chunks || []);
      setFetchingRunData(false);
    };

    fetchRunsData();
  }, [id]);

  const scrollToVideoByIndex = (index) => {
    let percentage = index / runData.run_accel_mag.length * 100;
    videosFlexRef.current.scrollLeft = (videosFlexRef.current.scrollWidth * percentage) / 100 - videosFlexRef.current.clientWidth / 2;
  };

  const changeTab = (index) => {
    updateSelectedVideoIndex(index)
    navigate(`/runs/${id}/videos`)
  }

  if (fetchingRunData) {
    return (
      <Flex
        height="calc(100vh - 70px)"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Flex flexDir='column' gap='20px'>
      <ContentHeader title="Inspection Data" description={"Run ID: " + runData.run_id} ml='0'/>
      <Flex flexDir='column' gap='12px'>
        <Text fontSize='20px' fontWeight='600' color='black'>
          Videos
        </Text>
        <Flex
          ref={videosFlexRef}
          flexWrap='nowrap'
          overflowX='auto'
          gap='16px'
          minChildWidth='170px'>
          {(videos || []).map((video_chunk, index) => {
            return (
              <Flex direction='column' flex='0 0 auto'>
                <Image
                  key={index}
                  src={video_chunk.video_chunk_thumbnail_url}
                  alt='video_chunk_thumbnail'
                  borderRadius='16px'
                  h='170px'
                  w='170px'
                  objectFit='cover'
                  mb='4px'
                  cursor='pointer'
                  onClick={() => changeTab(index)}
                />
                <Flex mt='4px' alignItems='center' gap='4px'>
                  <FaClock />
                  <Text fontSize='sm' textAlign='center' >
                    {moment(video_chunk.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                  </Text>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>

      <Flex flexDir='column' gap='12px'>
        <Text fontSize='20px' fontWeight='600' color='black'>
          Vibration
        </Text>
        <Box
          p='20px 24px'
          fontWeight='500'
          height='300px'
          border='1px solid'
          borderColor='border.300'
          borderRadius='12px'>
          <Text fontSize='16px' color='black'>
            3-Axis Acceleration Magnitude (g)
          </Text>
          <RunsChart  yLimit={1.8} 
                      onDataPointIndexChange={scrollToVideoByIndex} 
                      labelData='Magnitude Accelerometer' 
                      datas={runData.run_accel_mag || []}
                      annotations={runData.run_aruco_tags||[]} />
        </Box>
      </Flex>

      <Flex flexDir='column' gap='12px'>
        <Text fontSize='20px' fontWeight='600' color='black'>
          Rotations
        </Text>
        <Box
          p='20px 24px'
          fontWeight='500'
          height='300px'
          border='1px solid'
          borderColor='border.300'
          borderRadius='12px'>
          <RunsChart labelData='Magnitude Gyro' datas={runData.run_gyro_mag || []} annotations={[]} />
        </Box>
      </Flex>

      <Flex flexDir='column' gap='12px'>
        <Text fontSize='20px' fontWeight='600' color='black'>
          Airflow
        </Text>
        <Box
          p='20px 24px'
          fontWeight='500'
          height='300px'
          border='1px solid'
          borderColor='border.300'
          borderRadius='12px'>
          <RunsChart yLimit={7} labelData='Airflow m/s' datas={runData.run_airflow || []} annotations={[]} />
        </Box>
      </Flex>

      <Flex flexDir='column' gap='12px'>
        <Text fontSize='20px' fontWeight='600' color='black'>
          Temperatures
        </Text>
        <Box
          p='20px 24px'
          fontWeight='500'
          height='300px'
          border='1px solid'
          borderColor='border.300'
          borderRadius='12px'>
          
          <RunsChart 
              labelData="Temperatures"
              yLimit={60}
              datas={[
                {
                  label: 'Temperature 1-72',
                  data: runData.run_temperature_172 || []
                },
                {
                  label: 'Temperature 1-73',
                  data: runData.run_temperature_173 || []
                },
                {
                  label: 'Temperature 1-75',
                  data: runData.run_temperature_175 || []
                },
                {
                  label: 'Temperature 0-72',
                  data: runData.run_temperature_072 || []
                },
                {
                  label: 'Temperature 0-73',
                  data: runData.run_temperature_073 || []
                },
                {
                  label: 'Temperature 0-75',
                  data: runData.run_temperature_075 || []
                }
              ]}
              annotations={[]} 
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default RunOverView;
