import sAxios from './sAxios';

export default class DataService {
  /**
  * Handles user login by sending username and password to the login endpoint.
  * @param {string} username - The user's username.
  * @param {string} password - The user's password.
  * @returns {Promise<Object>} The server response data.
  */
  static async login(username, password) {
    try {
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('password', password);

      const response = await sAxios.post('/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log('Login successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error; // Re-throw the error to be handled by the login UI component
    }
  }


  static async getAllTrays() {
    const response = await sAxios.get(`/trays`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getAllRuns(trayId) {
    const response = await sAxios.get(`/trays/${trayId}/runs`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async getRunById(runId) {
    const response = await sAxios.get(`/runs/${runId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  static async updateRunsById(runId, objectToBeUpdated) {
    const response = await sAxios.put(
      `/runs/${runId}`,
      { ...objectToBeUpdated },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  }

  static async uploadFile(fileToBeUploaded) {
    const formData = new FormData();
    formData.append('file', fileToBeUploaded[0]);

    const response = await sAxios.post(`/runs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  }

  static async updateVideoChunksById(video_chunk_id, objectTobeUpdated) {
    const response = await sAxios.put(
      `/video_chunks/${video_chunk_id}`,
      { ...objectTobeUpdated },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  }

  static async getVideoChunksById(video_chunk_id) {
    const response = await sAxios.get(`/video_chunks/${video_chunk_id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}
