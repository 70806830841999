import {
  Box,
  Text,
  Flex,
  Input,
  useToast,
  Icon,
  Spinner,
} from '@chakra-ui/react';
import React, { useContext, useEffect,useRef, useState } from 'react';

import { GrFormPreviousLink } from 'react-icons/gr';
import { GrFormNextLink } from 'react-icons/gr';

import { ControlBar, Player } from 'video-react';
import "video-react/dist/video-react.css";

import DataService from '../../services/data.service';
import { AppContext } from '../../context/app.context';

import { FaClock } from 'react-icons/fa6';
import moment from 'moment';
import ContentHeader from '../ContentHeader';

import css from './index.module.css';
import { useParams } from 'react-router';

const RunVideos = () => {
  let { id } = useParams();

  const { videos, selectedVideoIndex, initializeVideos, updateVideo } =
    useContext(AppContext);
  const [fetchingRunData, setFetchingRunData] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const playerRef = useRef(null);

  const toast = useToast();

  const [currentChunkInfo, setCurrentChunkInfo] = useState(
    videos[selectedVideoIndex || 0]
  );
  const [currentChunkIndex, setCurrentChunkIndex] = useState(
    selectedVideoIndex || 0
  );

  const [annotation, setAnnotation] = useState(
    videos[selectedVideoIndex || 0]?.annotation || ''
  );

  useEffect(() => {
    const fetchRunsData = async () => {
      if (!videos || videos.length === 0) {
        console.log('INVOKING AN API');
        setFetchingRunData(true);
        const runData = await DataService.getRunById(id);

        const videoChunks = runData?.run_video_chunks || [];

        console.log({
          videoChunks,
          selectedVideoIndex,
          Test: videoChunks[selectedVideoIndex || 0],
        });

        initializeVideos(videoChunks);
        setCurrentChunkInfo(videoChunks[selectedVideoIndex || 0]);
        setAnnotation(videoChunks[selectedVideoIndex || 0]?.annotation || '');

        setFetchingRunData(false);
      }
    };

    fetchRunsData();
  }, [id, videos]);

  const handleAnnotation = async (e, id) => {
    const data = { video_chunk_id: id, annotation: annotation };
    await DataService.updateVideoChunksById(id, data);

    updateVideo(data)

    toast({
      title: 'Chunk updated successfully!',
      status: 'success',
      isClosable: true,
      duration: 2000,
    });
  };

  const handlePreviousChunk = () => {
    const updatedCurrentChunkIndex =
      currentChunkIndex === 0 ? videos.length - 1 : currentChunkIndex - 1;
    const currentChunkInfo = videos[updatedCurrentChunkIndex];

    setCurrentChunkIndex(updatedCurrentChunkIndex);
    setCurrentChunkInfo(currentChunkInfo);

    setAnnotation(currentChunkInfo?.annotation || '');
  };

  const handleNextChunk = () => {
    const updatedCurrentChunkIndex =
      currentChunkIndex === videos.length - 1 ? 0 : currentChunkIndex + 1;
    const currentChunkInfo = videos[updatedCurrentChunkIndex];

    setCurrentChunkIndex(updatedCurrentChunkIndex);
    setCurrentChunkInfo(currentChunkInfo);

    setAnnotation(currentChunkInfo?.annotation || '');
  };

  useEffect(() => {
    const handleUserKeyPress = (event) => {
      if (event.keyCode === 32 && !inputFocused) { // Space bar code
        event.preventDefault();
        togglePlayPause();
      } else if (event.keyCode === 37 && !inputFocused) { // Left arrow
        handlePreviousChunk();
      } else if (event.keyCode === 39 && !inputFocused) { // Right arrow
        handleNextChunk();
      }
    };

    const togglePlayPause = () => {
      const currentPlayer = playerRef.current;
      if (currentPlayer) {
        if (currentPlayer.getState().player.paused) {
          currentPlayer.play();
        } else {
          currentPlayer.pause();
        }
      }
    };

    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handlePreviousChunk, handleNextChunk]);

  if (fetchingRunData) {
    return (
      <Flex
        height='calc(100vh - 70px)'
        alignItems='center'
        justifyContent='center'>
        <Spinner size='lg' />
      </Flex>
    );
  }

  return (
    <Flex direction='column'>
      <ContentHeader
        title='Videos'
        description={`${moment(currentChunkInfo?.timestamp).format(
          'YYYY-MM-DD'
        )} / ${id}`}
        ml='0'
      />
      <Flex alignItems='center' justifyContent='space-between' my='16px'>
        <Flex gap='18px'>
          <Input
            flex='1'
            placeholder='Add a comment'
            value={annotation}
            maxW='400px'
            onChange={(e) => setAnnotation(e.target.value)}
            onKeyDown={(e) => {
              if(e.key === 'Enter') {
                handleAnnotation(e, currentChunkInfo?.video_chunk_id)
              }
            }}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
          <Flex mt='4px' alignItems='center' gap='8px' flex='1'>
            <Icon as={FaClock} w='20px' h='20px' />
            <Text fontSize='14px'>
              {moment(currentChunkInfo?.timestamp).format(
                'YYYY-MM-DD HH:mm:ss'
              )}
            </Text>
          </Flex>
        </Flex>
        <Flex gap='4px'>
          <Icon
            as={GrFormPreviousLink}
            w='32px'
            h='32px'
            color='white'
            bg='camera.blue.300'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
            p='4px 8px'
            cursor='pointer'
            onClick={() => handlePreviousChunk()}
          />
          <Icon
            as={GrFormNextLink}
            w='32px'
            h='32px'
            color='white'
            bg='camera.blue.300'
            borderRadius='50%'
            justifyContent='center'
            alignItems='center'
            p='4px 8px'
            cursor='pointer'
            onClick={() => handleNextChunk()}
          />
        </Flex>
      </Flex>
      <Box w='full' alignSelf='center'>
        <Player
          ref={playerRef}
          className='player'
          src={currentChunkInfo?.video_chunk_url}
          key={currentChunkIndex}
          autoPlay={false}>
          <ControlBar autoHide={false} className={css.videoControl} />
        </Player>
      </Box>
    </Flex>
  );
};

export default RunVideos;
