import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../../context/app.context';
import { Box } from '@chakra-ui/react';
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AppContext); // Use the context to get the current authentication status

  if (!isAuthenticated) {
    console.log('Redirecting to login...');
    // Redirect to login page if not authenticated
    return <Navigate to='/login' />;
  }else{
    console.log('Authenticated');
  }

  return children; // Render the children components if authenticated
};

export default ProtectedRoute;
