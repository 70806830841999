import axios from 'axios';

import Utils from './utils';

let apiUrl;

if (process.env.NODE_ENV === 'production') {
  apiUrl = `${window.location.protocol}//${window.location.host}/`;
} else if (process.env.REACT_APP_API_URL) {
  apiUrl = process.env.REACT_APP_API_URL;
}else{
  apiUrl = "http://localhost:8000";
}


const sAxios = axios.create({
  baseURL: apiUrl,
  withCredentials: true
});

const getBasicAuthHeader = () => {
  const username = process.env.REACT_APP_API_USERNAME;
  const password = process.env.REACT_APP_API_PASSWORD;
  const token = btoa(`${username}:${password}`);
  console.log(process.env);
  return `Basic ${token}`;
};

sAxios.CancelToken = axios.CancelToken;
sAxios.isCancel = axios.isCancel;


sAxios.interceptors.request.use(async (config) => {
  /*if (process.env.NODE_ENV !== 'production') {
    const authHeader = getBasicAuthHeader();
    config.headers.Authorization = authHeader;
  }*/
  return config;
});

sAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (sAxios.isCancel(error)) {
      return {
        data: {
          type: 'canceled',
          error: error.message || 'Aborted',
        },
      };
    }

    console.log(error);
    let response = {};
    if (error.response) {
      response = error.response;
    } else if (error.request) {
      response = {
        data: {
          type: 'error',
          message: error.responseText || error.message,
        },
      };
    } else {
      response = {
        data: {
          type: 'error',
          message: error.message,
        },
      };
    }

    return await Utils.handleResponseError(response);
  }
);

export default sAxios;
