import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Icon,
  Image,
  Spinner,
  Stack,
  Text,
  useToast, Tooltip,
  Button
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PiCamera, PiCircle } from 'react-icons/pi';
import { BiTable } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import moment from 'moment/moment';
import { DataService } from '../../services';

import FolderUploader from '../FileUpload';

const DeviceOverview = () => {
  const [trayData, setTrayData] = useState([]);
  const [fetchingTrayData, setFetchingTrayData] = useState(false);
  const [uploadingData, setUploadingData] = useState({});

  const toast = useToast();

  const handleUploadData = async (file, trayId) => {
    try {
      setUploadingData({ ...uploadingData, [trayId]: true });
      await DataService.uploadFile(file);
      setUploadingData({ ...uploadingData, [trayId]: false });

      toast({
        title: 'File uploaded successfully!',
        status: 'success',
        isClosable: true,
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: 'Error while uploading file.!',
        status: 'error',
        isClosable: true,
        duration: 2000,
      });
    }
  };

  useEffect(() => {
    const fetchTrayData = async () => {
      try {
        setFetchingTrayData(true);

        const response = await DataService.getAllTrays();

        setTrayData(response);
        setFetchingTrayData(false);
      } catch (error) {
        toast({
          title: 'Error while fetching traydata.!',
          status: 'error',
          isClosable: true,
          duration: 2000,
        });
      }
    };

    fetchTrayData();
  }, []);

  return (
    <Flex flexDir="column" margin="auto" m="20px 0 0 20px">
      <Box>
        <Text fontSize="32px" color="black" fontWeight="600">
          My Molds
        </Text>
      </Box>

      {fetchingTrayData ? (
        <Flex
          h="calc(100vh - 268px)"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner size="md" />
        </Flex>
      ) : (
        <Flex mt="20px" gap="40px">
          {trayData?.map((tray) => (
            <Card
              maxW="350px"
              key={tray.tray_id}
              borderRadius="20px"
              boxShadow="rgba(0, 0, 0, 0.05) 0px 5px 14px"
            >
              <Image
                src={tray.thumbnail_url}
                borderRadius="20px 20px 0px 0px"
                textAlign="center"
                w="100%"
              />

              <CardBody>
                <Flex mt="8px" flexWrap="wrap" gap="20px">
                  <Flex gap="12px" alignItems="center">
                    <Box alignSelf="center" mt="8px">
                      <Icon
                        as={PiCamera}
                        p="4px"
                        bg="camera.text"
                        borderRadius="8px"
                        w="24px"
                        h="24px"
                        color="white"
                      />
                    </Box>

                    <Box>
                      <Text fontSize="14px" fontWeight="700">
                        Serial Number
                      </Text>

                      <Text fontSize="12px" color="camera.text">
                        {tray.serial_number}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex gap="12px" alignItems="center">
                    <Box alignSelf="center" mt="8px">
                      <Icon
                        as={PiCircle}
                        p="4px"
                        bg="camera.text"
                        borderRadius="8px"
                        w="24px"
                        h="24px"
                        color="white"
                      />
                    </Box>

                    <Box>
                      <Text fontSize="14px" fontWeight="700">
                        Status
                      </Text>

                      <Text fontSize="12px" color="camera.text">
                        {(() => {
                          const lastSeen = new Date(tray.last_seen); // Convert last_seen to a Date object
                          const now = new Date(); // Get the current time
                          const timeDifference = (now - lastSeen) / 1000 / 60; // Difference in minutes
                          var upload_progress = 0;

                          if (tray.total_files) {
                            upload_progress = Math.floor((tray.uploaded_files / tray.total_files) * 100);
                          }

                          if (timeDifference <= 5 && upload_progress === 0) {
                            return (
                              <Tooltip label={`State: ${tray.state} - IP: ${tray.ip} - Local time: ${tray.local_time} `} fontSize="10px">
                                <span>Online</span>
                              </Tooltip>
                            );
                          } else if (timeDifference <= 5 && upload_progress !== 0) {
                            return (
                              <Tooltip label={`${tray.uploaded_files} of ${tray.total_files} files uploaded - IP: ${tray.ip} - Local time: ${tray.local_time} `} fontSize="10px">
                                <span>{tray.state} {upload_progress}%</span>
                              </Tooltip>
                            );
                          } else{
                            return (
                              <Tooltip label={`Last seen: ${lastSeen.toLocaleString()} - IP: ${tray.ip} - Local time: ${tray.local_time}`} fontSize="10px">
                                <span>Offline</span>
                              </Tooltip>
                            );
                          }
                        })()}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </CardBody>

              <CardFooter
                p="12px 12px 20px"
                justifyContent="space-between"
                alignItems="center"
              >

                <NavLink
                  to={`/trays/${tray.tray_id}`}
                  style={{ lineHeight: '0' }}
                >
                  <Button
                    borderRadius='16px'
                    p='0px 24px'
                    mr='2'
                    bg='camera.blue.light'
                    _hover={{ backgroundColor: '#a1aebf' }}
                    color='white'
                    fontWeight='700'
                    size='sm'>
                    View Inspections
                  </Button>

                </NavLink>

                <FolderUploader tray={tray} onFileDrop={handleUploadData}/>

              </CardFooter>
            </Card>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default DeviceOverview;
